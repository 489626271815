<template>
  <div class="container">
    <p class="section-name">{{ section_name }}</p>
    <el-tabs v-model="activeName" :stretch="true" style="margin-top: 15px">
      <el-tab-pane label="中文" name="first">
        <div v-html="content_chinese" class="article"></div>
      </el-tab-pane>
      <el-tab-pane label="English" name="second">
        <div v-html="content_english" class="article" style="white-space: pre-wrap;"></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {getElecSection} from "@/api/project";

export default {
  data() {
    return {
      activeName: "first",
      section_name: "",
      content_chinese: "",
      content_english: "",
    };
  },
  mounted() {
    this.fetchElecSection();
  },
  methods: {
    async fetchElecSection() {
      try {
        const res = await getElecSection({
          id: this.$route.query.id,
        });
        let result = res.data.data;
        this.section_name = result.name;
        let content = result.content;
        this.content_chinese = content[0];
        if (content.length > 1) {
          this.content_english = content[1];
        }
      } catch (error) {
        console.log(error);
      }
    },
    goBack() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 20px !important;
}

/deep/ .el-tabs__item.is-active {
  color: #213a8b;
}

/deep/ .el-tabs__active-bar {
  background-color: #213a8b;
}

.container {
  height: 100%;
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
}

.section-name {
  font-size: 28px;
  color: #333333;
  font-weight: 500;
  font-family: PingFang SC-Medium, PingFang SC;
}

.article {
  overflow: auto;
  /*overflow-x: auto;*/
  /*overflow-y: auto;*/
  margin-top: 10px;
  width: 100%;
  padding: 20px 0px;
  line-height: 28px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  box-sizing: border-box;
}
.article >>> img {
  max-width: 100% !important;
}
</style>
